import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, H2 } from '_templates/Sections';
import breakpoints from '_util/responsiveDesign/breakpoints';
import FirstDataIsNowFiservIllustration from '_images/svg/illustrations/FirstDataIsNowFiserv.svg';
import GlobalPayments from '_images/svg/illustrations/GlobalPayments.svg';

// Extract responsive design variables.
const { mobile, tablet } = breakpoints;

/**
 * Container for the graphics.
 */
const ContainerGraphics = styled.div`
  display: flex;

  @media screen and (max-device-width: ${mobile.max}px) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
  }

  img {
    width: 100%;
  }

  svg {
    width: 100%;
  }

  margin-top: 50px;
`;

/**
 * Container for the SVG.
 */
const ContainerSVG = styled.div`
  @media screen and (max-device-width: ${mobile.max}px) {
    flex-basis: 100%;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    flex-basis: 35%;
  }
`;

/**
 * It Pays To Know Your Options section.
 *
 * @returns {React.Component} Section.
 */
const ItPaysToKnowYourOptions = () => (
  <ContainerOneColumn>
    <H2>It pays to know your options.</H2>

    <p>
      Independent Sales Organization and Referral Agreements with Fiserv (formerly First Data) and
      Global Payments help Pricematepay provide the best possible options for your business.
    </p>

    <ContainerGraphics>
      <ContainerSVG>
        <FirstDataIsNowFiservIllustration />
      </ContainerSVG>

      <ContainerSVG>
        <GlobalPayments />
      </ContainerSVG>
    </ContainerGraphics>
  </ContainerOneColumn>
);

export default ItPaysToKnowYourOptions;
