import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import IntegratedIllustration from '_images/svg/illustrations/Integrated.svg';

import { ContainerGraphicWide } from './common/Section';

/**
 * Integrated Payments section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const IntegratedPayments = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <IntegratedIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>Integrated Payments</H2>

      <p>
        Our technology works with almost all POS systems &mdash; so you can keep your existing
        system in place with a new low-fee merchant account through Pricematepay.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default IntegratedPayments;
