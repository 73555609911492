import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import GiftCardsIllustration from '_images/svg/illustrations/GiftCards.svg';

import { ContainerGraphicWide } from './common/Section';

/**
 * Gift Cards section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const GiftCards = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <GiftCardsIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>Gift Cards</H2>

      <p>
        Easily sell and accept physical and digital gift cards to boost sales, attract new customers
        and boost brand awareness.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default GiftCards;
