import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import LoyaltyCardsIllustration from '_images/svg/illustrations/LoyaltyCards.svg';

import { ContainerGraphicWide } from './common/Section';

/**
 * Loyalty Programs section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const LoyaltyPrograms = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <LoyaltyCardsIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>Loyalty Programs</H2>

      <p>
        Reward repeat customers with loyalty points and discount programs as part of your
        Pricematepay merchant solution.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default LoyaltyPrograms;
