import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import POSTerminalsIllustration from '_images/svg/illustrations/POSTerminals.svg';

import { ContainerGraphicWide } from './common/Section';

/**
 * Terminals section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const Terminals = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <POSTerminalsIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>Terminals</H2>

      <p>
        Get the latest terminal technology and bring transactions right to your customers while
        improving payment speeds, service, and security.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default Terminals;
