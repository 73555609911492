import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import ShoppingIllustration from '_images/svg/illustrations/Shopping.svg';

import { ContainerGraphicWide } from './common/Section';

/**
 * E-Commerce section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const ECommerce = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <ShoppingIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>E-Commerce</H2>

      <p>
        We work with multiple payment gateway providers in addition to our own to ensure the right
        fit, function and pricing for your online merchant account.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default ECommerce;
