import React from 'react';

import {
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  ContainerButtons,
  H1,
} from '_templates/LandingSection';
import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import InternalLinkButton from '_components/InternalLinkButton';
import PharmacyIllustration from '_images/svg/illustrations/Pharmacy.svg';

/**
 * The "Make Every Transaction Count For More" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const MakeEveryTransactionCountForMore = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <PharmacyIllustration />
    </ContainerGraphic>

    <ContainerText>
      <H1>Make every transaction count for more</H1>

      <p>
        Whether you’re an established business or setting up shop for the first time, Pricematepay
        can provide complete payment solutions that maximize the value of every sale you make.
      </p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </ContainerText>
  </ContainerTwoColumn>
);

export default MakeEveryTransactionCountForMore;
