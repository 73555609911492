import styled from 'styled-components';

/**
 * Container for a graphic with a wide aspect ratio.
 */
const ContainerGraphicWide = styled.div`
  svg {
    max-width: 350px;
    width: 100%;
    height: auto;
  }

  text-align: center;
`;

/**
 * Container for a graphic with a tall aspect ratio.
 */
const ContainerGraphicTall = styled.div`
  svg {
    width: auto;
    height: 100%;
    max-height: 350px;
  }

  text-align: center;
`;

export { ContainerGraphicWide, ContainerGraphicTall };
