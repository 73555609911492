import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import VirtualTerminalsIllustration from '_images/svg/illustrations/VirtualTerminals.svg';

import { ContainerGraphicTall } from './common/Section';

/**
 * Virtual Terminals section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const VirtualTerminals = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicTall>
      <VirtualTerminalsIllustration />
    </ContainerGraphicTall>

    <div>
      <H2>Virtual Terminals</H2>

      <p>
        Quickly process online payments with intuitive virtual terminal solutions, paired with
        Pricematepay’s fair pricing models for your merchant account.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default VirtualTerminals;
