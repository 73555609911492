import React from 'react';

import { ContainerOneColumn, H2 } from '_templates/Sections';

/**
 * We're Always In Your Corner section.
 *
 * @returns {React.Component} Section.
 */
const WereAlwaysInYourCorner = () => (
  <ContainerOneColumn>
    <H2>We&apos;re always in your corner</H2>

    <p>
      With Pricematepay, you get an extra layer of customer support for your merchant services.
      While you can always call your processing company’s 24/7 service number to get the support you
      require, sometimes it’s nice to pass the heavy lifting off to someone who knows how to get
      things done quickly with your acquirer and escalate your issues to the top when you need it
      most.
    </p>
  </ContainerOneColumn>
);

export default WereAlwaysInYourCorner;
